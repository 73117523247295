import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logo.gif'; 

const Header = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="bg-white text-green-700 font-bold p-4 md:p-8 sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center">
        
        <Link to="/">
          <img src={logo} alt="Voice Logo" className="h-16 md:h-24 w-auto" />
        </Link>
        <nav className="hidden md:flex space-x-8">
          <Link
            to="/"
            className={`relative pb-2 ${
              location.pathname === '/' ? 'text-green-900' : 'text-green-700'
            }`}
          >
            <span className={`absolute left-0 bottom-0 w-full border-b-2 ${
              location.pathname === '/' ? 'border-green-700' : 'border-transparent'
            }`} />
            Home
          </Link>
          <Link
            to="/about-us"
            className={`relative pb-2 ${
              location.pathname === '/about-us' ? 'text-green-900' : 'text-green-700'
            }`}
          >
            <span className={`absolute left-0 bottom-0 w-full border-b-2 ${
              location.pathname === '/about-us' ? 'border-green-700' : 'border-transparent'
            }`} />
            About Us
          </Link>
          <Link
            to="/services"
            className={`relative pb-2 ${
              location.pathname === '/services' ? 'text-green-900' : 'text-green-700'
            }`}
          >
            <span className={`absolute left-0 bottom-0 w-full border-b-2 ${
              location.pathname === '/services' ? 'border-green-700' : 'border-transparent'
            }`} />
            Services
          </Link>
          <Link
            to="/life-voice"
            className={`relative pb-2 ${
              location.pathname === '/life-voice' ? 'text-green-900' : 'text-green-700'
            }`}
          >
            <span className={`absolute left-0 bottom-0 w-full border-b-2 ${
              location.pathname === '/life-voice' ? 'border-green-700' : 'border-transparent'
            }`} />
            Life @ Voice
          </Link>
          <Link
            to="/blog"
            className={`relative pb-2 ${
              location.pathname === '/blog' ? 'text-green-900' : 'text-green-700'
            }`}
          >
            <span className={`absolute left-0 bottom-0 w-full border-b-2 ${
              location.pathname === '/blog' ? 'border-green-700' : 'border-transparent'
            }`} />
            Blogs & News
          </Link>
          <Link
            to="/contact-us"
            className={`relative pb-2 ${
              location.pathname === '/contact-us' ? 'text-green-900' : 'text-green-700'
            }`}
          >
            <span className={`absolute left-0 bottom-0 w-full border-b-2 ${
              location.pathname === '/contact-us' ? 'border-green-700' : 'border-transparent'
            }`} />
            Contact Us
          </Link>
        </nav>
        
        <div className="md:hidden">
          <button onClick={toggleMobileMenu} className="text-green-700 focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>
      
      {isMobileMenuOpen && (
        <nav className="md:hidden bg-white text-green-700 p-4">
          <Link
            to="/"
            className={`block py-2 ${
              location.pathname === '/' ? 'text-green-900' : 'text-green-700'
            }`}
            onClick={toggleMobileMenu}
          >
            Home
          </Link>
          <Link
            to="/about-us"
            className={`block py-2 ${
              location.pathname === '/about-us' ? 'text-green-900' : 'text-green-700'
            }`}
            onClick={toggleMobileMenu}
          >
            About Us
          </Link>
          <Link
            to="/services"
            className={`block py-2 ${
              location.pathname === '/services' ? 'text-green-900' : 'text-green-700'
            }`}
            onClick={toggleMobileMenu}
          >
            Services
          </Link>
          <Link
            to="/life-voice"
            className={`block py-2 ${
              location.pathname === '/life-voice' ? 'text-green-900' : 'text-green-700'
            }`}
            onClick={toggleMobileMenu}
          >
            Life @ Voice
          </Link>
          <Link
            to="/blog"
            className={`block py-2 ${
              location.pathname === '/blog' ? 'text-green-900' : 'text-green-700'
            }`}
            onClick={toggleMobileMenu}
          >
            Blogs & News
          </Link>
          <Link
            to="/contact-us"
            className={`block py-2 ${
              location.pathname === '/contact-us' ? 'text-green-900' : 'text-green-700'
            }`}
            onClick={toggleMobileMenu}
          >
            Contact Us
          </Link>
        </nav>
      )}
    </header>
  );
};

export default Header;