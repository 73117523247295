import React from 'react';

const HeroSection = ({ title, subtitle }) => {
  return (
    <section className="bg-green-100 py-12 text-center">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4">{title}</h1>
        <p className="text-base sm:text-lg md:text-xl">{subtitle}</p>
      </div>
    </section>
  );
};

export default HeroSection;