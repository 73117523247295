import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import './index.css'; // Import Tailwind CSS
import App from './app/App';

// Create the root element
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component, wrapped with the Redux Provider
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
