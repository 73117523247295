import React, { useState } from 'react';
import missionImage from '../assets/mission.png';
import founder1 from '../assets/founder1.jpeg';
import founder2 from '../assets/founder2.jpeg';
import founder3 from '../assets/founder3.jpeg';

const timelineData = [
  { year: 'August 2023', description: 'Officially started operations, marking the beginning of EV91 Technologies’ journey and generated ₹24,640 with 1,066 orders delivered.' },
  { year: 'November 2023', description: 'Crossed ₹10 lakh in earnings, reaching ₹16,96,124 and successfully delivered 52,534 orders in a single month.' },
  { year: 'February 2024', description: 'Introduced the first fleet of 107 electric vehicles, with operations commencing in key cities like Chennai and Bangalore.' },
  { year: 'March 2024', description: 'Achieved another major revenue milestone, crossing ₹50 lakh, reaching ₹53,79,875 and delivered 1,84,170 orders.' },
  { year: 'July 2024', description: 'Reached ₹1 crore+ revenue for the first time and processed over 2.7 lakh orders in a single month.' },
];

const Timeline = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <section className="py-16 bg-green-100">
      <div className="container mx-auto px-4 max-w-4xl">
        <div className="relative">
          <div className="absolute w-full h-1 bg-gray-300 top-1/2 transform -translate-y-1/2"></div>
          <div className="flex flex-col md:flex-row justify-between items-center mb-12 relative z-10">
            {timelineData.map((item, index) => (
              <div key={index} className="flex flex-col items-center mb-8 md:mb-0">
                <button
                  className={`w-10 h-10 mb-6 rounded-full z-10 ${
                    index === activeIndex ? 'bg-green-800' : 'bg-gray-400'
                  }`}
                  onClick={() => setActiveIndex(index)}
                >
                  <span className="sr-only">{`Year ${item.year}`}</span>
                </button>
                {index === activeIndex && (
                  <div className="mt-8 text-center bg-white p-4 rounded-lg shadow-md w-full md:w-64 h-auto flex items-center justify-center">
                    <div>
                      <h3 className="text-xl md:text-2xl font-bold mb-2 text-green-800">{`In ${item.year}`}</h3>
                      <p className="text-sm md:text-gray-700">{item.description}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const MeetTheTeam = () => {
  const teamData = [
    {
      name: 'Arun Kumar',
      image: founder1,
      designation: 'Founder and CEO',
      experience: '12+ years of experience in Sales',
    },
    {
      name: 'Lalith Kumar',
      image: founder2,
      designation: 'Co-Founder and CFO',
      experience: '12+ years of experience in Finance',
    },
    {
      name: 'Manoj Kumar',
      image: founder3,
      designation: 'Co-Founder and COO',
      experience: '12+ years of experience in operations',
    },
  ];

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-8 text-center text-green-800">Meet the Team</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {teamData.map((member, index) => (
            <div key={index} className="text-center">
              <img
                src={member.image}
                alt={member.name}
                className="w-32 h-32 rounded-full mx-auto mb-4 object-cover"
              />
              <h3 className="text-xl md:text-2xl font-semibold text-green-800">{member.name}</h3>
              <p className="text-sm md:text-gray-700">{member.designation}</p>
              <p className="text-sm md:text-gray-500">{member.experience}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const AboutUs = () => {
  return (
    <div>
      <section className="py-12 bg-green-100">
        <div className="container mx-auto px-4">
          <div className="bg-white p-8 rounded-2xl shadow-xl">
            <div className="flex flex-col md:flex-row items-center">
              <div className="mt-6 md:mt-0 md:ml-6">
                <h1 className="text-3xl md:text-5xl font-bold mb-4 text-green-800 text-center">About Us</h1>
                <h3 className="text-3xl md:text-5xl font-bold mb-4 text-green-800 text-center">Welcome to VOICE!</h3>
                <p className="text-base md:text-lg text-gray-700 mb-4 text-center">
                  Where innovation meets sustainability. We are dedicated to transforming the electric mobility landscape with cutting-edge solutions and a passion for a greener future.
                </p>
                <p className="text-base md:text-lg text-gray-700 text-center">
                  Our team is committed to driving progress and creating a lasting impact. Discover who we are and what drives us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-green-100 text-green-800">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-12 text-center">Our Mission</h2>
          <div className="flex flex-col md:flex-row items-center">
            <div className="relative w-full md:w-1/2 h-auto">
              <img
                src={missionImage}
                alt="Our Mission"
                className="w-full h-auto rounded-xl object-cover"
                style={{ mixBlendMode: 'multiply' }}
              />
            </div>
            <div className="mt-6 md:mt-0 md:ml-6 text-center md:text-left">
              <div className="max-w-full md:max-w-3xl mx-auto">
                <h2 className="text-3xl md:text-4xl font-bold mb-4 text-green-800">Our Commitment</h2>
                <p className="text-base md:text-xl text-black mb-4">
                  At VOICE, we are passionate about revolutionizing sustainable mobility and driving positive change for our planet. Our mission is to spearhead innovation in electric vehicle solutions, setting new standards in eco-friendly transportation that benefit both the environment and society.
                </p>
                <p className="text-base md:text-xl text-black mb-4">
                  We are committed to pushing the boundaries of technology, continuously advancing our solutions to deliver unmatched efficiency and performance. Our goal is to lead the charge in creating a greener future, where every innovation contributes to a cleaner, more sustainable world.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-center text-green-800">Our Values</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-green-700 p-6 rounded-lg shadow-lg text-center text-white">
              <h3 className="text-xl md:text-2xl font-semibold mb-2">Innovation</h3>
              <p className="text-sm md:text-base">
                We continuously push the boundaries of technology to bring you the latest in electric mobility solutions.
              </p>
            </div>
            <div className="bg-green-700 p-6 rounded-lg shadow-lg text-center text-white">
              <h3 className="text-xl md:text-2xl font-semibold mb-2">Sustainability</h3>
              <p className="text-sm md:text-base">
                Our solutions are designed with the environment in mind, promoting a cleaner and greener future.
              </p>
            </div>
            <div className="bg-green-700 p-6 rounded-lg shadow-lg text-center text-white">
              <h3 className="text-xl md:text-2xl font-semibold mb-2">Customer-Centricity</h3>
              <p className="text-sm md:text-base">
                We prioritize your needs and work tirelessly to deliver exceptional service and support.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Timeline />
      <MeetTheTeam />
    </div>
  );
};

export default AboutUs;
