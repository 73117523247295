import React, { useState, useEffect, useRef } from 'react';
import evImage from '../assets/ev-image.png';
import milestoneImage from '../assets/milestone-image.png';
import clientLogo1 from '../assets/client-logo1.png';
import clientLogo2 from '../assets/client-logo2.png';
import clientLogo3 from '../assets/client-logo3.png';
import clientLogo4 from '../assets/client-logo4.png';
import clientLogo5 from '../assets/client-logo5.png';
import clientLogo6 from '../assets/client-logo6.png';
import clientLogo7 from '../assets/client-logo7.png';
import clientLogo8 from '../assets/client-logo8.png';
import clientLogo9 from '../assets/client-logo9.png';
import partnerLogo1 from '../assets/partner-logo1.png';
import partnerLogo2 from '../assets/partner-logo2.png';
import partnerLogo3 from '../assets/partner-logo3.png';
import partnerLogo4 from '../assets/partner-logo4.png';
import partnerLogo5 from '../assets/partner-logo5.png';
import partnerLogo6 from '../assets/partner-logo6.png';

const CountUp = ({ end, duration, isVisible }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!isVisible) return;

    let start = 0;
    const increment = end / (duration / 16);
    const timer = setInterval(() => {
      start += increment;
      setCount(Math.floor(start));
      if (start >= end) {
        clearInterval(timer);
        setCount(end);
      }
    }, 16);

    return () => clearInterval(timer);
  }, [end, duration, isVisible]);

  return <span>{count.toLocaleString()}+</span>;
};

const Carousel = ({ items }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <div className="relative">
      <div className="relative w-full overflow-hidden">
        <div className="flex justify-center transition-transform duration-500">
          {items.map((item, index) => (
            <div
              key={index}
              onClick={() => handleClick(index)}
              className={`flex-shrink-0 w-80 mx-4 transform transition-transform duration-500 ${
                index === selectedIndex ? 'scale-100 z-10' : 'scale-90'
              }`}
              style={{
                cursor: 'pointer',
                zIndex: index === selectedIndex ? 10 : 1,
              }}
            >
              <div className={`p-6 ${index === selectedIndex ? 'bg-green-800' : 'bg-green-700'} text-white shadow-lg rounded-lg relative`}>
                <h3 className="text-xl font-semibold mb-4 text-center">{item.title}</h3>
                <p className="text-center">{item.content}</p>
                <div className="absolute inset-0 border-4 border-white rounded-lg pointer-events-none"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  const impactRef = useRef(null);
  const clientsRef = useRef(null);
  const partnersRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (impactRef.current) {
      observer.observe(impactRef.current);
    }

    return () => {
      if (impactRef.current) {
        observer.unobserve(impactRef.current);
      }
    };
  }, [impactRef]);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (clientsRef.current) {
        if (clientsRef.current.scrollLeft >= clientsRef.current.scrollWidth / 2) {
          clientsRef.current.scrollLeft = 0;
        } else {
          clientsRef.current.scrollLeft += 2;
        }
      }
    }, 30);

    return () => clearInterval(scrollInterval);
  }, []);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (partnersRef.current) {
        if (partnersRef.current.scrollLeft >= partnersRef.current.scrollWidth / 2) {
          partnersRef.current.scrollLeft = 0;
        } else {
          partnersRef.current.scrollLeft += 2;
        }
      }
    }, 30);

    return () => clearInterval(scrollInterval);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const clientLogos = [
    clientLogo1, clientLogo2, clientLogo3, clientLogo4,
    clientLogo5, clientLogo6, clientLogo7, clientLogo8, clientLogo9
  ];

  const partnerLogos = [
    partnerLogo1, partnerLogo2, partnerLogo3, partnerLogo4,
    partnerLogo5, partnerLogo6
  ];

  const carouselItems = [
    { title: 'EV-Based Delivery', content: 'Experience swift, reliable delivery with our top-notch service. Your goods, delivered with precision and care wherever you need them.' },
    { title: '3PL (Third-Party Logistics)', content: 'Streamline your supply chain with our reliable third-party logistics solutions. Efficient and scalable logistics tailored for your business.' },
    { title: 'EV-Rental', content: 'Drive the future with our premium EV rentals—eco-friendly and efficient. Rent an electric vehicle today and experience sustainable mobility.' },
  ];

  return (
    <div>
      <section className="py-12 bg-green-100 animate-jump-left">
        <div className="container mx-auto px-4">
          <div className="bg-white p-8 rounded-2xl shadow-xl">
            <div className="flex flex-col md:flex-row items-center">
              <img
                src={evImage}
                alt="Electric Vehicle"
                className="w-full md:w-1/2 h-auto rounded-xl shadow-md"
              />
              <div className="mt-6 md:mt-0 md:ml-6">
                <h1 className="text-5xl font-bold mb-4 text-green-800 text-center">EV91Technologies is now VOICE!</h1>
                <p className="text-lg text-gray-700 mb-4 text-center">
                  Ushering in a new era of innovation and progress in the electric mobility landscape.
                </p>
                <p className="text-lg text-gray-700 text-center">
                  The rebrand reflects a commitment to pioneering advancements and a resonant voice in shaping the future of sustainable mobility.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section ref={impactRef} className="py-16 bg-green-800 text-white animate-jump-right">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-12 text-center">Our Impact</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-green-700 p-6 rounded-lg shadow-lg text-center">
              <h3 className="text-2xl font-semibold mb-2">CO2 Saved</h3>
              <p className="text-5xl font-bold mb-2">
                <CountUp end={84749} duration={2000} isVisible={isVisible} />
              </p>
              <p>tons</p>
            </div>
            <div className="bg-green-700 p-6 rounded-lg shadow-lg text-center">
              <h3 className="text-2xl font-semibold mb-2">Equivalent Trees Planted</h3>
              <p className="text-5xl font-bold mb-2">
                <CountUp end={338996} duration={2000} isVisible={isVisible} />
              </p>
              <p>trees</p>
            </div>
            <div className="bg-green-700 p-6 rounded-lg shadow-lg text-center">
              <h3 className="text-2xl font-semibold mb-2">Deliveries Made</h3>
              <p className="text-5xl font-bold mb-2">
                <CountUp end={1903527} duration={2000} isVisible={isVisible} />
              </p>
              <p>deliveries</p>
            </div>
            <div className="bg-green-700 p-6 rounded-lg shadow-lg text-center">
              <h3 className="text-2xl font-semibold mb-2">Jobs Created</h3>
              <p className="text-5xl font-bold mb-2">
                <CountUp end={900} duration={2000} isVisible={isVisible} />
              </p>
              <p>jobs</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-white relative animate-jump-left">
        <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
          <div className="relative w-full md:w-1/2 h-auto">
            <img
              src={milestoneImage}
              alt="Milestone"
              className="w-full h-auto rounded-xl object-cover"
              style={{ mixBlendMode: 'multiply' }}
            />
          </div>
          <div className="mt-6 md:mt-0 md:ml-6 text-center md:text-left">
            <h2 className="text-4xl font-bold mb-4 text-green-800">Milestone Achieved!</h2>
            <p className="text-lg text-gray-700 mb-4">
              We have reached a significant milestone in our journey towards sustainable mobility.
            </p>
            <p className="text-lg text-gray-700">
              Thank you for being part of this incredible journey. Together, we are making a difference.
            </p>
          </div>
        </div>
      </section>

      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-center text-green-800">Our Services</h2>
          <Carousel items={carouselItems} />
        </div>
      </section>

      <section className="py-16 animate-jump-right">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-center text-green-800">Our Valuable Clients</h2>
          <div
            ref={clientsRef}
            className="flex overflow-x-auto py-4"
            style={{
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              scrollBehavior: 'smooth',
              scrollbarWidth: 'none', /* Firefox */
              msOverflowStyle: 'none', /* IE and Edge */
            }}
          >
            {[...clientLogos, ...clientLogos].map((logo, index) => (
              <img
                key={index}
                src={logo}
                alt={`Client ${index + 1}`}
                className="h-24 w-auto object-contain mx-4 inline-block"
                style={{ flexShrink: 0 }}
              />
            ))}
          </div>
        </div>
      </section>

      <section className="py-16 animate-jump-left">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-center text-green-800">Our Partners</h2>
          <div
            ref={partnersRef}
            className="flex overflow-x-auto py-4"
            style={{
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              scrollBehavior: 'smooth',
              scrollbarWidth: 'none', /* Firefox */
              msOverflowStyle: 'none', /* IE and Edge */
            }}
          >
            {[...partnerLogos, ...partnerLogos].map((logo, index) => (
              <img
                key={index}
                src={logo}
                alt={`Partner ${index + 1}`}
                className="h-24 w-auto object-contain mx-4 inline-block"
                style={{ flexShrink: 0 }}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
