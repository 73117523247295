import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchServices } from './serviceAPI';


export const getServices = createAsyncThunk('services/getServices', async () => {
  const response = await fetchServices();
  return response.data;
});

const serviceSlice = createSlice({
  name: 'services',
  initialState: { services: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServices.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.services = action.payload;
      })
      .addCase(getServices.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default serviceSlice.reducer;
