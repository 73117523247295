import React from 'react';
import HeroSection from '../components/HeroSection';
import thumbnailImage from '../assets/thumbnail.jpg'; 
import youtubeThumbnail from '../assets/youtube-thumbnail.jpeg'; 

const Blog = () => {
  return (
    <div>
      <HeroSection title="Blogs & News" subtitle="Read our latest articles and updates." />

      
      <section className="py-8 bg-white">
        <div className="container mx-auto flex flex-col md:flex-row items-center mb-8">
          <div className="md:w-1/3">
            <img src={thumbnailImage} alt="VOICE partners with BGauss" className="w-full h-auto rounded-lg shadow-md" />
          </div>
          <div className="md:w-2/3 md:ml-8 mt-4 md:mt-0">
            <a
              href="https://www.outlookmoney.com/outlook-money-spotlight/voice-partners-with-bgauss-to-deploy-5000-electric-vehicles-launches-new-logo-8951"
              target="_blank"
              rel="noopener noreferrer"
              className="text-green-800 underline text-lg"
            >
              VOICE partners with BGauss to deploy 5000 electric vehicles, launches new logo
            </a>
            <p className="text-gray-700 mt-2">
              Discover how VOICE is revolutionizing the logistics industry with its new partnership and branding.
            </p>
          </div>
        </div>

        
        <div className="container mx-auto flex flex-col md:flex-row items-center">
          <div className="md:w-1/3">
            <img src={youtubeThumbnail} alt="YouTube Video" className="w-full h-auto rounded-lg shadow-md" />
          </div>
          <div className="md:w-2/3 md:ml-8 mt-4 md:mt-0">
            <a
              href="https://www.youtube.com/watch?v=7vM4MN07j-M"
              target="_blank"
              rel="noopener noreferrer"
              className="text-green-800 underline text-lg"
            >
              EV91TECHNOLOGIES PRIVATE LIMITED for winning at the Karnataka Business Awards 2024
            </a>
            <p className="text-gray-700 mt-2">
              Explore our recent developments, milestones and insights in the electric vehicle industry.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;