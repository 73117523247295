import React, { useState, useEffect } from 'react';
import HeroSection from '../components/HeroSection';
import cultureImage from '../assets/Life.png'; 
import careImage from '../assets/care-image.png'; 

const LifeVoice = () => {
  const [showJobs, setShowJobs] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showApplyForm, setShowApplyForm] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    experience: '',
    resume: null,
  });
  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);

  const jobs = [
    {
      title: 'Assistant Manager - Finance',
      location: 'Bangalore',
      aboutUs: 'At VOICE, we are revolutionizing the logistics industry with our innovative electric vehicle (EV) solutions. Our mission is to provide sustainable, efficient, and eco-friendly logistics services that contribute to a greener future.',
      jobSummary: 'We are seeking a dynamic and detail-oriented Assistant Manager - Finance to join our team. The successful candidate will play a crucial role in managing financial operations, ensuring accuracy, and providing insights that drive our business forward. If you are passionate about finance and sustainability, and thrive in a fast-paced startup environment, we would love to hear from you.',
      responsibilities: [
        'Financial Planning & Analysis: Assist in the preparation of financial forecasts, budgets, and financial reports. Analyze financial data to support strategic decision-making.',
        'Accounting & Compliance: Oversee daily accounting operations including accounts payable/receivable, general ledger entries, and bank reconciliations. Ensure compliance with financial regulations and standards.',
        'Cash Flow Management: Monitor cash flow to ensure sufficient liquidity for operational needs. Develop strategies to optimize cash management.',
        'Financial Reporting: Prepare and present regular financial reports to senior management. Assist in the preparation of financial statements and reports for stakeholders.',
        'Cost Management: Analyze and manage costs to ensure efficient operations. Identify cost-saving opportunities and implement cost control measures.',
        'Audit & Controls: Coordinate with external auditors and manage internal controls to ensure accuracy and compliance with financial policies.',
        'Team Collaboration: Work closely with other departments to support financial planning and reporting needs. Provide financial insights and recommendations to support business initiatives.'
      ],
      experience: [
        'Educational Background: Bachelor’s degree in Finance, Accounting, Business Administration, or a related field. Professional certification (e.g., CPA, CFA) is a plus.',
        'Experience: Minimum of 3-5 years of experience in finance or accounting roles, with a focus on financial planning and analysis, accounting, and reporting.',
        'Industry Knowledge: Experience in the logistics or EV sector is advantageous but not required. Familiarity with startup environments and fast-paced financial operations is preferred.',
        'Skills: Strong analytical and problem-solving skills. Proficiency in financial software and Microsoft Excel. Excellent attention to detail and accuracy. Effective communication and interpersonal skills.'
      ],
      perks: [
        'Competitive Salary: Attractive salary package based on experience and qualifications.',
        'Health Benefits: Comprehensive health insurance plans, including medical, dental, and vision coverage.',
        'Flexible Working Hours: Option for flexible working hours and remote work arrangements.',
        'Professional Development: Opportunities for career growth and development through training and mentorship programs.',
        'Innovative Environment: Work in a cutting-edge EV-based logistics startup with a focus on sustainability and innovation.',
        'Team Culture: Collaborative and supportive work culture with team-building activities and a focus on employee well-being.'
      ]
    },
    {
      title: 'Assistant Manager EV - Rider Sourcing',
      location: 'Bangalore',
      aboutUs: 'At VOICE, we are revolutionizing the logistics industry with our innovative electric vehicle (EV) solutions. Our mission is to provide sustainable, efficient, and eco-friendly logistics services that contribute to a greener future.',
      jobSummary: 'We are looking for an enthusiastic and experienced Assistant Manager - EV Rider Sourcing to join our team. The ideal candidate will be responsible for sourcing and managing a network of EV riders to ensure efficient and reliable delivery services. This role requires a strategic thinker with strong negotiation skills and a passion for enhancing operational efficiency.',
      responsibilities: [
        'Rider Sourcing: Develop and execute strategies for sourcing and recruiting EV riders. Identify and engage potential riders through various channels including job boards, social media, and local networks.',
        'Recruitment & Onboarding: Oversee the recruitment process, including screening, interviewing, and onboarding new riders. Ensure a smooth integration into the company’s operations and culture.',
        'Performance Management: Monitor and evaluate rider performance, providing feedback and implementing improvement plans as needed. Address any performance issues promptly.',
        'Relationship Building: Build and maintain strong relationships with riders, addressing their concerns and ensuring high levels of satisfaction. Foster a positive and motivated riding community.',
        'Operational Coordination: Collaborate with other departments to align rider sourcing strategies with operational needs. Ensure that rider availability meets demand and operational requirements.',
        'Compliance & Training: Ensure riders comply with company policies, safety standards, and legal regulations. Organize and deliver training programs to enhance rider skills and knowledge.',
        'Data Analysis: Track and analyze key metrics related to rider performance and sourcing effectiveness. Use data to drive improvements and optimize sourcing strategies.',
        'Cost Management: Manage budgets related to rider recruitment and sourcing. Identify cost-effective solutions and negotiate terms with external partners when applicable.'
      ],
      experience: [
        'Educational Background: Bachelor’s degree in Business Administration, Human Resources, Logistics, or a related field.',
        'Experience: Minimum of 3-5 years of experience in rider or driver recruitment, logistics management, or a related role. Experience in the EV or transportation sector is a plus.',
        'Skills: Strong interpersonal and communication skills. Proven ability to negotiate and build relationships. Excellent organizational and problem-solving abilities. Proficiency in Microsoft Office and experience with recruitment software or applicant tracking systems.',
        'Knowledge: Familiarity with rider compliance requirements, safety regulations, and best practices in logistics.'
      ],
      perks: [
        'Competitive Salary: Attractive salary package based on experience and qualifications.',
        'Health Benefits: Comprehensive health insurance plans, including medical, dental, and vision coverage.',
        'Flexible Working Hours: Option for flexible working hours and remote work arrangements.',
        'Professional Development: Opportunities for career growth and development through training and mentorship programs.',
        'Innovative Environment: Work in a cutting-edge EV-based logistics startup with a focus on sustainability and innovation.',
        'Team Culture: Collaborative and supportive work culture with team-building activities and a focus on employee well-being.'
      ]
    },
    {
      title: 'Content Writer',
      location: 'Bangalore',
      aboutUs: 'At VOICE, we are revolutionizing the logistics industry with our innovative electric vehicle (EV) solutions. Our mission is to provide sustainable, efficient, and eco-friendly logistics services that contribute to a greener future.',
      jobSummary: 'We are seeking a talented and creative Content Writer to join our team. The ideal candidate will have a passion for writing and a knack for crafting engaging and persuasive content. This role involves producing high-quality content for various platforms, including websites, blogs, social media, and marketing materials.',
      responsibilities: [
        'Content Creation: Write clear, compelling, and original content for various formats including web pages, blog posts, articles, social media updates, email campaigns, and marketing collateral.',
        'Research: Conduct thorough research on industry-related topics to ensure content accuracy and relevance. Stay updated with industry trends and audience preferences.',
        'SEO Optimization: Implement SEO best practices in content creation to improve search engine rankings and drive organic traffic.',
        'Editing & Proofreading: Review and edit content for grammar, punctuation, style, and consistency. Ensure all content is polished and aligns with brand guidelines.',
        'Content Strategy: Collaborate with the marketing team to develop content strategies that align with business objectives and target audience needs.',
        'Content Management: Manage and organize content using content management systems (CMS) and ensure timely publishing of content.',
        'Feedback & Revisions: Incorporate feedback from stakeholders and make necessary revisions to content as required.',
      ],
      experience: [
        'Educational Background: Bachelor’s degree in English, Journalism, Communications, Marketing, or a related field.',
        'Experience: Minimum of 2-4 years of experience in content writing or a related role. Experience in [specific industry or type of content, e.g., tech, finance, lifestyle] is a plus.',
        'Skills: Exceptional writing, editing, and proofreading skills. Strong command of grammar and style. Ability to write in different tones and styles based on audience and platform. Proficiency in SEO and familiarity with content management systems (CMS) like WordPress.',
        'Portfolio: Proven track record of writing high-quality content. A portfolio showcasing previous work is required.'
      ],
      perks: [
        'Competitive Salary: Attractive salary package based on experience and qualifications.',
        'Health Benefits: Comprehensive health insurance plans, including medical, dental, and vision coverage.',
        'Flexible Working Hours: Option for flexible working hours and remote work arrangements.',
        'Professional Development: Opportunities for career growth and development through training and mentorship programs.',
        'Innovative Environment: Work in a cutting-edge EV-based logistics startup with a focus on sustainability and innovation.',
        'Team Culture: Collaborative and supportive work culture with team-building activities and a focus on employee well-being.'
      ]
    },
    {
      title: 'Digital Marketing Associate',
      location: 'Bangalore',
      aboutUs: 'At VOICE, we are revolutionizing the logistics industry with our innovative electric vehicle (EV) solutions. Our mission is to provide sustainable, efficient, and eco-friendly logistics services that contribute to a greener future.',
      jobSummary: 'We are looking for a motivated and detail-oriented Digital Marketing Associate to support our digital marketing efforts. The ideal candidate will have a strong understanding of digital marketing channels and tactics, with a passion for optimizing campaigns and driving results. This role involves assisting with the development, execution, and analysis of digital marketing campaigns.',
      responsibilities: [
        'Campaign Management: Assist in the planning, execution, and management of digital marketing campaigns across various channels including social media, email, search engines, and display advertising.',
        'Content Creation: Collaborate with the content team to create engaging and relevant content for digital platforms, including social media posts, blog articles, and email newsletters.',
        'Analytics & Reporting: Monitor and analyze campaign performance using tools like Google Analytics, social media insights, and other analytics platforms. Prepare regular reports on key performance metrics and make data-driven recommendations.',
        'SEO & SEM: Support SEO strategies to improve website rankings and drive organic traffic. Assist with paid search campaigns (PPC) to optimize ad performance and budget allocation.',
        'Social Media Management: Manage and schedule social media posts, engage with followers, and monitor social media trends and feedback.',
        'Email Marketing: Assist in the creation and execution of email marketing campaigns, including segmentation, personalization, and A/B testing.',
        'Market Research: Conduct research on industry trends, competitor activities, and consumer behavior to inform marketing strategies and tactics.',
      ],
      experience: [
        'Educational Background: Educational Background: Bachelor’s degree in Marketing, Business, Communications, or a related field.',
        'Experience: Experience: 1-3 years of experience in digital marketing or a related role. Familiarity with digital marketing tools and platforms is preferred.',
        'Skills: Strong understanding of digital marketing channels, including social media, SEO, SEM, and email marketing. Proficiency in using analytics tools such as Google Analytics. Excellent written and verbal communication skills. Ability to work collaboratively in a team environment and manage multiple tasks simultaneously.',
        'Technical Skills: Basic knowledge of HTML/CSS, experience with CMS platforms (e.g., WordPress), and familiarity with design tools (e.g., Canva) is a plus.'
      ],
      perks: [
        'Competitive Salary: Attractive salary package based on experience and qualifications.',
        'Health Benefits: Comprehensive health insurance plans, including medical, dental, and vision coverage.',
        'Flexible Working Hours: Option for flexible working hours and remote work arrangements.',
        'Professional Development: Opportunities for career growth and development through training and mentorship programs.',
        'Innovative Environment: Work in a cutting-edge EV-based logistics startup with a focus on sustainability and innovation.',
        'Team Culture: Collaborative and supportive work culture with team-building activities and a focus on employee well-being.'
      ]
    },
    {
      title: 'Field Recruiter',
      location: 'Bangalore',
      aboutUs: 'At VOICE, we are revolutionizing the logistics industry with our innovative electric vehicle (EV) solutions. Our mission is to provide sustainable, efficient, and eco-friendly logistics services that contribute to a greener future.',
      jobSummary: 'The Field Recruiter will play a crucial role in sourcing and recruiting qualified EV riders for our logistics operations. This role involves field-based recruitment activities, engaging with potential candidates, and managing the recruitment process to ensure we attract and hire the best talent to support our growth in the EV sector.',
      responsibilities: [
        'Talent Sourcing: Actively source and identify potential EV riders through various channels including job fairs, community outreach, and direct engagement.',
        'Candidate Engagement: Conduct outreach to potential candidates, explain job roles, and assess their fit for the EV rider positions.',
        'Field Recruitment Activities: Organize and participate in recruitment events, job fairs, and community programs focused on sourcing EV riders.',
        'Screening and Interviews: Perform initial candidate screenings, schedule interviews, and conduct assessments to evaluate suitability for EV rider roles.',
        'Coordination: Collaborate with hiring managers to understand specific requirements and ensure a smooth recruitment process.',
        'Relationship Building: Develop strong relationships with candidates and local communities to build a robust talent pipeline.',
        'Market Analysis: Stay informed about trends in the EV industry and labor market to enhance recruitment strategies.',
        'Reporting: Maintain accurate records of recruitment activities, candidate interactions, and metrics. Prepare regular reports for management.',
        'Compliance: Ensure all recruitment activities adhere to legal requirements and company policies.',
      ],
      experience: [
        'Educational Background: Bachelor’s degree in Human Resources, Business Administration, or a related field preferred.',
        'Experience: 2-4 years of experience in recruitment, with a focus on field-based recruitment or sourcing roles.',
        'Skills: Strong understanding of recruitment strategies and sourcing techniques, particularly in the logistics or transportation sector.',
        'Technical Skills: Proficiency in using applicant tracking systems (ATS) and recruitment software. Familiarity with social media platforms for recruitment.',
        'Soft Skills: Excellent communication, interpersonal, and organizational skills. Ability to engage with diverse candidates and manage multiple recruitment tasks.',
        'Industry Knowledge: Knowledge of the EV industry and an understanding of the specific requirements for EV rider roles is highly desirable.'
      ],
      perks: [
        'Competitive Salary: Attractive salary package based on experience and qualifications.',
        'Health Benefits: Comprehensive health insurance plans, including medical, dental, and vision coverage.',
        'Flexible Working Hours: Option for flexible working hours and remote work arrangements.',
        'Professional Development: Opportunities for career growth and development through training and mentorship programs.',
        'Innovative Environment: Work in a cutting-edge EV-based logistics startup with a focus on sustainability and innovation.',
        'Team Culture: Collaborative and supportive work culture with team-building activities and a focus on employee well-being.'
      ]
    },
    {
      title: 'Senior MIS Specialist',
      location: 'Bangalore',
      aboutUs: 'At VOICE, we are revolutionizing the logistics industry with our innovative electric vehicle (EV) solutions. Our mission is to provide sustainable, efficient, and eco-friendly logistics services that contribute to a greener future.',
      jobSummary: 'The Senior MIS Specialist will be responsible for overseeing the management information systems (MIS) within the organization, ensuring that data is accurately collected, analyzed, and presented to support strategic decision-making. This role involves managing and improving MIS processes, developing reports and dashboards, and collaborating with various departments to meet their information needs.',
      responsibilities: [
        'MIS Management: Oversee the design, development, and implementation of MIS solutions to meet organizational needs. Ensure data integrity and accuracy across all systems.',
        'Reporting and Analysis: Create, analyze, and present comprehensive reports and dashboards that support business decisions. Utilize data visualization tools to effectively communicate insights.',
        'System Optimization: Identify areas for improvement within existing MIS processes and implement solutions to enhance system performance and efficiency.',
        'Data Management: Ensure proper data collection, validation, and storage procedures. Maintain data security and confidentiality standards.',
        'Collaboration: Work closely with various departments to understand their reporting needs and provide tailored solutions. Act as a liaison between technical teams and end-users.',
        'Project Management: Lead and manage MIS projects from inception to completion. Coordinate with stakeholders to ensure project goals are met within deadlines and budgets.',
        'Technical Support: Provide technical support and training to users on MIS tools and systems. Troubleshoot and resolve system issues as they arise.',
        'Documentation: Maintain comprehensive documentation of MIS processes, procedures, and system configurations.'
      ],
      experience: [
        'Educational Background: Bachelor’s degree in Information Systems, Computer Science, Business Administration, or a related field. A Master’s degree or relevant certifications (e.g., PMP, CBIP) is a plus.',
        'Experience: 5+ years of experience in MIS or related roles, with a strong background in managing information systems and data analysis.',
        'Technical Skills: Proficiency in MIS tools, databases, and reporting software (e.g., SQL, Excel, BI tools like Power BI, Tableau). Experience with ERP systems is an advantage.',
        'Analytical Skills: Strong analytical and problem-solving skills with the ability to interpret complex data and provide actionable insights.',
        'Project Management: Experience in managing projects, including planning, execution, and delivery.',
        'Communication Skills: Excellent verbal and written communication skills. Ability to present data and findings to both technical and non-technical stakeholders.',
        'Attention to Detail: Strong attention to detail and commitment to maintaining data accuracy and integrity.'
      ],
      perks: [
        'Competitive Salary: Attractive salary package based on experience and qualifications.',
        'Health Benefits: Comprehensive health insurance plans, including medical, dental, and vision coverage.',
        'Flexible Working Hours: Option for flexible working hours and remote work arrangements.',
        'Professional Development: Opportunities for career growth and development through training and mentorship programs.',
        'Innovative Environment: Work in a cutting-edge EV-based logistics startup with a focus on sustainability and innovation.',
        'Team Culture: Collaborative and supportive work culture with team-building activities and a focus on employee well-being.'
      ]
    },
  ];

  const handleJobClick = (job) => {
    setSelectedJob(job);
  };

  const handleCloseJobModal = () => {
    setSelectedJob(null);
  };

  const handleOpenApplyForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      experience: '',
      resume: null,
    });
    setErrors({});
    setShowApplyForm(true);
  };

  const handleCloseApplyForm = () => {
    setShowApplyForm(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'resume') {
      if (files && files[0]) {
        const fileType = files[0].type;
        if (
          fileType === 'application/pdf' ||
          fileType === 'application/msword' ||
          fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
          setFormData({ ...formData, [name]: files[0] });
          setErrors({ ...errors, resume: '' });
        } else {
          setErrors({ ...errors, resume: 'Please upload a PDF or Word document' });
        }
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First Name is required';
    if (!formData.lastName) newErrors.lastName = 'Last Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'Phone number is invalid';
    }
    if (!formData.experience) newErrors.experience = 'Experience is required';
    if (!formData.resume) newErrors.resume = 'Resume is required';
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      console.log('Form submitted:', formData);
      setShowApplyForm(false);
      setSelectedJob(null);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);

      // Sending email with EmailJS or similar service
      // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', formData, 'YOUR_USER_ID')
      //   .then((result) => {
      //     console.log(result.text);
      //   }, (error) => {
      //     console.log(error.text);
      //   });

    } else {
      setErrors(validationErrors);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      handleCloseApplyForm();
      handleCloseJobModal();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div>
      <HeroSection title="Life @ Voice" subtitle="Experience life at Voice." />

      
      <section className="py-8 bg-green-100">
        <div className="container mx-auto flex flex-col md:flex-row items-center">
          <div className="md:w-1/2">
            <img src={cultureImage} alt="Culture" className="w-full h-auto rounded-lg shadow-md" />
          </div>
          <div className="md:w-1/2 md:ml-8 mt-4 md:mt-0">
            <h2 className="text-3xl font-bold mb-4 text-green-800">Culture</h2>
            <p className="text-lg text-gray-700">
              At our core, we see failure as a crucial learning opportunity. Progress comes from embracing risks and daring to innovate. Be the next change.
            </p>
          </div>
        </div>
      </section>

      
      <section className="py-8 bg-white">
        <div className="container mx-auto flex flex-col md:flex-row items-center">
          <div className="md:w-1/2">
            <img src={careImage} alt="We Care For You" className="w-full h-auto rounded-lg shadow-md" />
          </div>
          <div className="md:w-1/2 md:ml-8 mt-4 md:mt-0">
            <h2 className="text-3xl font-bold mb-4 text-green-800">We Care For You</h2>
            <div className="grid grid-cols-1 gap-4">
              <div className="bg-green-50 p-4 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-green-800">Medical Insurance</h3>
                <p className="text-gray-700">
                  Life, surgery, dental, inpatient, outpatient and more. Because we really care for you.
                </p>
              </div>
              <div className="bg-green-50 p-4 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-green-800">Community Learning</h3>
                <p className="text-gray-700">
                  Industry-wide meetups, events and conferences. We pay. You learn.
                </p>
              </div>
              <div className="bg-green-50 p-4 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-green-800">Unity in Diversity</h3>
                <p className="text-gray-700">
                  Work with superstars from across India.
                </p>
              </div>
              <div className="bg-green-50 p-4 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-green-800">Learning & Development</h3>
                <p className="text-gray-700">
                  Not getting a solution? Relax, our in-house experienced mentors are there to mentor you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      <section className="py-8 bg-green-100">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-4 text-green-800">Our Goal</h2>
          <p className="text-lg text-gray-700 mb-4">
            Our goal is to create and amplify impactful, environmentally friendly socio-economic benefits for our community of users.
          </p>
          <button
            onClick={() => setShowJobs(!showJobs)}
            className="mt-4 bg-green-800 text-white py-2 px-4 rounded"
          >
            Join Us ↓
          </button>
        </div>
      </section>

      
      {showJobs && (
        <section className="py-8 bg-white">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold mb-4 text-green-800 text-center">Explore Open Positions</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {jobs.map((job, index) => (
                <div
                  key={index}
                  className="bg-green-50 p-4 rounded-lg shadow-md cursor-pointer"
                  onClick={() => handleJobClick(job)}
                >
                  <h3 className="text-xl font-semibold text-green-800">{job.title}</h3>
                  <p className="text-gray-700">{job.location}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      
      {selectedJob && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full h-full mx-4 relative overflow-y-auto">
            <button
              onClick={handleCloseJobModal}
              className="absolute top-2 right-2 text-2xl text-gray-600 hover:text-gray-800"
            >
              &times;
            </button>
            <h3 className="text-2xl font-bold mb-4 text-green-800 text-center">{selectedJob.title}</h3>
            <p className="text-lg text-gray-700 mb-4"><strong>About Us:</strong> {selectedJob.aboutUs}</p>
            <p className="text-lg text-gray-700 mb-4"><strong>Job Summary:</strong> {selectedJob.jobSummary}</p>
            <h4 className="text-xl font-semibold text-green-800 mb-2">Key Responsibilities:</h4>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              {selectedJob.responsibilities.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <h4 className="text-xl font-semibold text-green-800 mb-2">Experience Required:</h4>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              {selectedJob.experience.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <h4 className="text-xl font-semibold text-green-800 mb-2">Perks Provided:</h4>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              {selectedJob.perks.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <div className="flex justify-center">
              <button
                onClick={handleOpenApplyForm}
                className="mt-4 bg-green-800 text-white py-2 px-4 rounded"
              >
                Apply Now
              </button>
            </div>
          </div>
        </div>
      )}

      
      {showApplyForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-xl mx-4 relative">
            <button
              onClick={handleCloseApplyForm}
              className="absolute top-2 right-2 text-2xl text-gray-600 hover:text-gray-800"
            >
              &times;
            </button>
            <h3 className="text-2xl font-bold mb-4 text-green-800 text-center">Application Form</h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
                {errors.firstName && <p className="text-red-600">{errors.firstName}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
                {errors.lastName && <p className="text-red-600">{errors.lastName}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
                {errors.email && <p className="text-red-600">{errors.email}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
                {errors.phone && <p className="text-red-600">{errors.phone}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Experience (Years)</label>
                <select
                  name="experience"
                  value={formData.experience}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                >
                  <option value="">Select</option>
                  {[...Array(10).keys()].map((num) => (
                    <option key={num + 1} value={num + 1}>
                      {num + 1}
                    </option>
                  ))}
                </select>
                {errors.experience && <p className="text-red-600">{errors.experience}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Upload Resume</label>
                <input
                  type="file"
                  name="resume"
                  onChange={handleChange}
                  accept=".pdf,.doc,.docx"
                  className="w-full px-3 py-2 border rounded"
                />
                {errors.resume && <p className="text-red-600">{errors.resume}</p>}
              </div>
              <div className="flex justify-center">
                <button type="submit" className="bg-green-800 text-white py-2 px-4 rounded">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      
      {showSuccess && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md mx-4 text-center">
            <div className="text-green-800 text-4xl mb-4">✔</div>
            <p className="text-lg text-gray-700 mb-4">
              Thank you for your interest in VOICE. We are overwhelmed with applications. Once you are shortlisted, you will hear from us through email. Cheers!!!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LifeVoice;